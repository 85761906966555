var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"myVerificationOfMobilePhone"}},[_c('el-form',{ref:"ruleForm",staticClass:"main-form",style:(_vm.styleForm),attrs:{"model":_vm.ruleForm}},[_c('el-col',{style:(_vm.colStyle),attrs:{"span":24}},[_c('el-form-item',{attrs:{"label":_vm.labelTitle1,"label-width":_vm.labelTitleWidth1,"prop":"phone","rules":[
          {
            required: true,
            message: '请输入内容',
            trigger: 'change'
          },
          {
            validator: _vm.validatePhone,
            trigger: 'blur'
          }
        ]}},[_c('el-input',{style:(_vm.inputPhone),attrs:{"type":"text","maxlength":_vm.maxlength1,"placeholder":_vm.placeholderPhone,"disabled":_vm.isDisablePhone},model:{value:(_vm.ruleForm.phone),callback:function ($$v) {_vm.$set(_vm.ruleForm, "phone", $$v)},expression:"ruleForm.phone"}})],1)],1),_c('el-col',{style:(_vm.colStyle),attrs:{"span":18}},[_c('el-form-item',{attrs:{"label":_vm.labelTitle2,"label-width":_vm.labelTitleWidth2,"prop":"message","rules":[
          {
            required: true,
            message: '请输入内容',
            trigger: 'change'
          }/*,
              {
                validator: validateScore,
                trigger: 'blur'
              }*/
        ]}},[_c('el-input',{style:(_vm.inputNote),attrs:{"type":"text","maxlength":_vm.maxlength2,"placeholder":_vm.placeholderNote},model:{value:(_vm.ruleForm.message),callback:function ($$v) {_vm.$set(_vm.ruleForm, "message", $$v)},expression:"ruleForm.message"}})],1)],1),_c('el-col',{staticStyle:{"height":"40px","display":"flex","overflow":"hidden"},attrs:{"span":6}},[(_vm.conceal)?_c('span',{staticClass:"largen largenColor",staticStyle:{"line-height":"40px","text-align":"center","font-size":"14px","width":"80%","height":"40px","cursor":"pointer","margin":"0px 0px 0px 10px"},on:{"click":_vm.verification}},[_vm._v(" 获取验证码 ")]):_vm._e(),(_vm.conceal3)?_c('span',{staticStyle:{"line-height":"40px","text-align":"center","font-size":"14px","width":"80%","height":"40px","background-color":"#f8f8f8","margin":"0px 0px 0px 10px","border-radius":"5px","color":"#C0C4CC"}},[_vm._v(" 获取验证码 ")]):_vm._e(),(_vm.conceal2)?_c('span',{staticStyle:{"color":"#bfbfbf","line-height":"40px","text-align":"center","font-size":"16px","font-weight":"600","width":"100%","height":"40px","cursor":"pointer"}},[_vm._v(" "+_vm._s(_vm.number)+" ")]):_vm._e()])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }