<template>
  <div id="myVerificationOfMobilePhone">
    <el-form
      ref="ruleForm"
      class="main-form"
      :style="styleForm"
      :model="ruleForm"
    >
      <!--手机号-->
      <el-col
        :span="24"
        :style="colStyle"
      >
        <el-form-item
          :label="labelTitle1"
          :label-width="labelTitleWidth1"
          prop="phone"
          :rules="[
            {
              required: true,
              message: '请输入内容',
              trigger: 'change'
            },
            {
              validator: validatePhone,
              trigger: 'blur'
            }
          ]"
        >
          <el-input
            v-model="ruleForm.phone"
            type="text"
            :maxlength="maxlength1"
            :placeholder="placeholderPhone"
            :style="inputPhone"
            :disabled="isDisablePhone"
          />
          <!--width: 240px;-->
        </el-form-item>

      </el-col>
      <!--验证码输入-->
      <el-col
        :span="18"
        :style="colStyle"
      >
        <el-form-item
          :label="labelTitle2"
          :label-width="labelTitleWidth2"
          prop="message"
          :rules="[
            {
              required: true,
              message: '请输入内容',
              trigger: 'change'
            }/*,
                {
                  validator: validateScore,
                  trigger: 'blur'
                }*/
          ]"
        >
          <el-input
            v-model="ruleForm.message"
            type="text"
            :maxlength="maxlength2"
            :placeholder="placeholderNote"
            :style="inputNote"
          />
        </el-form-item>
      </el-col>
      <!--获取验证码 -->
      <el-col
        :span="6"
        style="height: 40px; display: flex; overflow: hidden;"
      >
        <span
          v-if="conceal"
          class="largen largenColor"
          style="line-height: 40px;text-align: center;font-size: 14px;width: 80%;height: 40px;cursor: pointer;margin: 0px 0px 0px 10px;"
          @click="verification"
        >
          获取验证码
        </span>
        <span
          v-if="conceal3"
          style="line-height: 40px;text-align: center;font-size: 14px;width: 80%;height: 40px;background-color: #f8f8f8;margin: 0px 0px 0px 10px; border-radius: 5px; color: #C0C4CC;"
        >
          获取验证码
        </span>
        <span
          v-if="conceal2"
          style="color: #bfbfbf;line-height: 40px;text-align: center;font-size: 16px;font-weight: 600;width: 100%;height: 40px;cursor: pointer;"
        >
          {{ number }}
        </span>
      </el-col>

    </el-form>
  </div>
</template>

<script>
import { isPhone } from '../../util/validate';
export default {
  // import引入的组件需要注入到对象中才能使用",
  components: {},
  props: {
    /* 按钮是否隐藏*/
    conceal: {
      type: Boolean,
      default: true
    },
    conceal2: {
      type: Boolean,
      default: false
    },
    conceal3: {
      type: Boolean,
      default: false
    },
    /* 手机框的值*/
    phoneValue: {
      type: String,
      default: null
    },
    /* 验证码框的值*/
    messageValue: {
      type: String,
      default: null
    },
    /* 手机号输入框是否禁用*/
    isDisablePhone: {
      type: Boolean,
      default: false
    },
    /* form样式*/
    styleForm: {
      type: String,
      default: 'width: 480px; margin: 0px auto; height: 120px;'
    },

    /* 手机框标题*/
    labelTitle1: {
      type: String,
      default: '手机号'
    },
    /* 验证码框标题*/
    labelTitle2: {
      type: String,
      default: '验证码'
    },
    /* 手机框label宽度*/
    labelTitleWidth1: {
      type: String,
      default: '120px'
    },
    /* 验证码label宽度*/
    labelTitleWidth2: {
      type: String,
      default: '120px'
    },
    /* 手机号输入框长度限制*/
    maxlength1: {
      type: String,
      default: '11'
    },
    /* 验证码输入框长度限制*/
    maxlength2: {
      type: String,
      default: '6'
    },
    /* 手机号输入提示*/
    placeholderPhone: {
      type: String,
      default: '请输入11位的手机号'
    },
    /* 验证码输入提示*/
    placeholderNote: {
      type: String,
      default: '短信验证码'
    },
    /* 手机号输入框样式*/
    inputPhone: {
      type: String,
      default: 'width: 240px;'
    },
    /* 验证码输入框样式*/
    inputNote: {
      type: String,
      default: 'width: 240px;'
    },
    /* el-col高度*/
    colStyle: {
      type: String,
      default: 'height: 60px;'
    }
  },
  data() {
    // 这里存放数据",
    return {
      /* 验证按钮隐藏*/
      // conceal:true,
      // conceal2:false,
      number: -1,

      ruleForm: {
        phone: '',
        message: ''
      }
    };
  },
  // 监听属性 类似于data概念",
  computed: {},
  // 监控data中的数据变化",
  watch: {
    phoneValue(newValue) {
      if (newValue) {
        this.ruleForm.phone = newValue;
      }
    },
    messageValue(newValue) {
      if (newValue) {
        this.ruleForm.message = newValue;
      }
    }
  },
  // 生命周期 - 创建之前",数据模型未加载,方法未加载,html模板未加载
  beforeCreate() {

  },

  // 生命周期 - 创建完成（可以访问当前this实例）",数据模型已加载，方法已加载,html模板已加载,html模板未渲染
  created() {
    setInterval(this.showMarquee, 1000);
  },
  // 生命周期 - 挂载之前",html模板未渲染
  beforeMount() {

  },

  // 生命周期 - 挂载完成（可以访问DOM元素）",html模板已渲染
  mounted() {
    // this.confirm();
  },
  // 生命周期 - 更新之前",数据模型已更新,html模板未更新
  beforeUpdate() {

  },
  // 生命周期 - 更新之后",数据模型已更新,html模板已更新
  updated() {

  },
  // 生命周期 - 销毁之前",
  beforeDestroy() {

  },
  destroyed() {

  },
  // 生命周期 - 销毁完成",
  // 如果页面有keep-alive缓存功能，这个函数会触发",
  activated() {

  },
  // 方法集合",
  methods: {
    clearMessage() {
      this.$refs.ruleForm.resetFields();
    },
    // 校验电话
    validatePhone(rule, value, callback) {
      if (value) {
        if (isPhone(value)) {
          callback();
        } else {
          callback(
            new Error('格式错误，请重新输入')
          );
        }
      } else {
        callback();
      }
    },

    /* 确认*/
    confirm() {
      let that = this;
      this.$refs['ruleForm'].validate(valid => {
        if (valid) {
          // console.log(valid);
          let phone = that.ruleForm.phone;
          let message = that.ruleForm.message;
          that.$emit('returnedValue', phone, message, valid);
        } else {
          let phone = '';
          let message = '';
          that.$emit('returnedValue', phone, message, valid);
          return false;
        }
      });
    },
    /* 强制切换*/
    showCut() {
      this.conceal = true;
      this.conceal2 = false;
      this.number = -1;
    },
    /* 验证码计时*/
    showMarquee() {
      let number2 = 0;
      number2 = this.number - 1;
      this.number = number2;
      if (this.number === 0) {
        this.conceal = true;
        this.conceal2 = false;
      }
    },
    /* 获取验证码*/
    verification() {
      let that = this;
      if (that.ruleForm.phone !== '') {
        // console.log(isPhone(this.ruleForm.phone),"phone");
        if (isPhone(that.ruleForm.phone)) {
          that.conceal = false;
          that.conceal2 = true;
          that.number = 60;
          let phone = that.ruleForm.phone;
          that.$emit('getCode', phone);
        } else {
          that.$message.warning('手机号格式错误，请规范输入');
        }
      } else {
        that.$message.warning('请先输入手机号码');
      }
    }
  }
};
</script>

<style scoped>
  .largenColor {
    color: rgba(199,25,33,0.6);
  }

</style>
