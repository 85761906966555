<template>
  <div id="modifyThePhoneNumber">
    <el-dialog
      title="修改手机号"
      :visible.sync="visible"
      :append-to-body="isBody"
      :close-on-click-modal="isModal"
      width="600px"
      @close="close"
    >
      <div class="main" style="height: 140px; width: 100%;">
        <my-verification-of-mobile-phone
          ref="phone"
          :style-form="styleForm1"
          :label-title1="labelTitle1"
          :col-style="colStyle"
          @returnedValue="returnedValue"
          @getCode="getCode"
        />
      </div>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="close">取 消</el-button>
        <el-button type="primary" @click="sure">确 定</el-button>
      </span>

    </el-dialog>
  </div>
</template>

<script>
/*
   * 手机验证组件
   */
import MyVerificationOfMobilePhone from '../../../components/myVerificationOfMobilePhone/index';

/*
   *api
   */
import { checkSmsForUpdateMobile, sendSmsForUpdateMobile } from '../../../api/index';

export default {
  // import引入的组件需要注入到对象中才能使用",
  components: { MyVerificationOfMobilePhone },

  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    isModal: {
      type: Boolean,
      default: false
    },
    isBody: {
      type: Boolean,
      default: true
    }
  },
  data() {
    // 这里存放数据",
    return {
      valid: '',
      phone: '',
      ruleForm: {
        mobileNumber: '',
        code: ''
      },
      visible: this.dialogVisible,
      /* 验证按钮隐藏*/
      conceal: true,
      conceal2: false,
      styleForm1: 'width: 480px; margin: 0px auto; height: 160px;',
      labelTitle1: '新手机号',
      colStyle: 'height: 80px'
    };
  },
  // 监听属性 类似于data概念",
  computed: {},
  // 监控data中的数据变化",
  watch: {
    dialogVisible: function(val) {
      this.visible = val;
    }

  },
  // 生命周期 - 创建之前",数据模型未加载,方法未加载,html模板未加载
  beforeCreate() {

  },

  // 生命周期 - 创建完成（可以访问当前this实例）",数据模型已加载，方法已加载,html模板已加载,html模板未渲染
  created() {
  },
  // 生命周期 - 挂载之前",html模板未渲染
  beforeMount() {

  },

  // 生命周期 - 挂载完成（可以访问DOM元素）",html模板已渲染
  mounted() {

  },
  // 生命周期 - 更新之前",数据模型已更新,html模板未更新
  beforeUpdate() {

  },
  // 生命周期 - 更新之后",数据模型已更新,html模板已更新
  updated() {

  },
  // 生命周期 - 销毁之前",
  beforeDestroy() {

  },
  destroyed() {

  },
  // 生命周期 - 销毁完成",
  // 如果页面有keep-alive缓存功能，这个函数会触发",
  activated() {

  },
  // 方法集合",
  methods: {
    /*
      * 校验验证码
      * 校验验证码（更换手机号）
      * /user/checkSmsForUpdateMobile
      * get
      * code=200更新手机号成功，500验证码已失效
      */
    sure() {
      let that = this;
      that.$refs.phone.confirm();
      if (that.valid) {
        if (that.phone !== '') {
          if (that.phone === that.ruleForm.mobileNumber) {
            checkSmsForUpdateMobile({
              mobileNumber: that.ruleForm.mobileNumber,
              code: that.ruleForm.code
            }).then(res => {
              if (res.data.code === 200) {
                that.$message.success(res.data.msg);
                that.close();
                that.confirm();
              } else {
                that.$message.error(res.data.msg);
                return false;
              }
            }).catch(err => {
			    if (err.response) {
				  that.$message.error(err.response.data.errorDescription);
				  console.log(err.response.headers);
				  let loginUrl = err.response.headers.loginurl;
				  console.log('loginUrl' + loginUrl);
				  if (loginUrl) {
				    let backUrl = window.location.hash;
				    let localBaseIndexOf = loginUrl.indexOf('redirect_uri=');
				    if (localBaseIndexOf != -1) {
					  let localBase = loginUrl.substring(localBaseIndexOf + 13, loginUrl.indexOf('oauth2-login'));
					  backUrl = window.location.href.replace(localBase, '');
				    }
				    // 跳转到系统登录页面
				    // debugger
				   console.log(loginUrl + '?backUrl=' + encodeURIComponent(encodeURIComponent(backUrl)));
				   window.location.href = loginUrl + '?backUrl=' + encodeURIComponent(encodeURIComponent(backUrl));
				  }
			     }
			  });
          } else {
            this.$message.warning('手机号被修改，请重新校验！');
            return false;
          }
        } else {
          that.$message.warning('请先获取验证码，并输入正确的验证码！');
          return false;
        }
      } else {
        return false;
      }
    },
    /*
      * 手机校验
      * 发送新手机的短信（更新手机号）
      * /user/sendSmsForUpdateMobile
      * get
      * code=200更新手机号成功，500验证码已失效
      */
    getCode(phone) {
      let that = this;
      this.phone = phone;
      sendSmsForUpdateMobile({
        mobileNumber: this.phone
      }).then(res => {
        if (res.data.code === 200) {
          that.$message.success(res.data.msg);
        } else if (res.data.code === 501) {
          that.$refs.phone.showCut();
          that.$message.error(res.data.msg);
          return false;
        } else {
          that.$message.error(res.data.msg);
          return false;
        }
      }).catch(err => {
        if (err.response) {
          that.$message.error(err.response.data.errorDescription);
          console.log(err.response.headers);
          let loginUrl = err.response.headers.loginurl;
          console.log('loginUrl' + loginUrl);
          if (loginUrl) {
            let backUrl = window.location.hash;
            let localBaseIndexOf = loginUrl.indexOf('redirect_uri=');
            if (localBaseIndexOf != -1) {
              let localBase = loginUrl.substring(localBaseIndexOf + 13, loginUrl.indexOf('oauth2-login'));
              backUrl = window.location.href.replace(localBase, '');
            }
            // 跳转到系统登录页面
            // debugger
            console.log(loginUrl + '?backUrl=' + encodeURIComponent(encodeURIComponent(backUrl)));
            window.location.href = loginUrl + '?backUrl=' + encodeURIComponent(encodeURIComponent(backUrl));
          }
        }
      });
    },
    /*
       * 调用子组件confirm方法
       * 返回：
       * 电话 phone，
       * 短信 message，
       * valid 是否校验成功
       */
    returnedValue(phone, message, valid) {
      this.valid = valid;
      this.ruleForm.mobileNumber = phone;
      this.ruleForm.code = message;
    },

    // 关闭
    close() {
      this.$refs.phone.clearMessage();
      this.phone = '';
      this.valid = '';
      this.ruleForm.mobileNumber = '';
      this.ruleForm.message = '';
      this.visible = false;
      this.$emit('closeModifyThePhone', false);
    },
    confirm() {
      this.$emit('confirm');
    }

  }
};
</script>

<style scoped lang="scss">
  #modifyThePhoneNumber {

    .main-form-span1 {
      border: 1px solid blue;
    }
  }
</style>
