<template>
  <div id="personalCenter">
    <div class="header" />
    <div class="up">
      <div class="up-left">个人中心</div>
      <div class="up-right" @click="previousPage">
        <i class="up-right-i1 iconfont icon-fanhui" />
        <label class="up-right-i2">返回</label>
      </div>
    </div>
    <div class="below">

      <div class="main">
        <el-tabs
          v-model="activeName"
          style="height: 100%; width: 100%;"
          tab-position="left"
        >
          <!--个人资料-->
          <el-tab-pane
            label="个人资料"
            name="personalInformation"
            style="overflow: auto;height: 100%; width: 100%;"
          >
            <div class="main-information-up">个人资料</div>
            <div class="main-information-below">
              <!--头像-->
              <img class="main-information-below-div1" :src="imgSrc"></img>
              <!--姓名-->
              <div class="main-information-below-div2">
                <label class="main-information-below-div2-label">{{ personalInformationData.name }}</label>
              </div>
              <div class="main-information-below-div3">基本信息</div>
              <!--基本信息-->
              <div class="main-information-below-div4">
                <div class="main-information-below-div4-li">
                  <label>性别：</label>
                  <label>{{ sex }}</label>
                </div>
                <div class="main-information-below-div4-li">
                  <label>电话：</label>
                  <label>{{ personalInformationData.phone }}</label>
                  <i
                    class="main-information-below-div4-li-label iconfont icon-xiugai"
                    @click="modifyThePhone"
                  />
                </div>
                <div class="main-information-below-div4-li">
                  <label>邮箱：</label>
                  <label>{{ email }}</label>
                </div>
                <div class="main-information-below-div4-li">
                  <label>证件类型：</label>
                  <label>{{ certificateType }}</label>
                </div>
                <div class="main-information-below-div4-li">
                  <label>证件号：</label>
                  <label>{{ personalInformationData.certificateNum }}</label>
                </div>
                <div class="main-information-below-div4-li" />
              </div>

              <div class="main-information-below-div3">身份信息</div>
              <div class="main-information-below-div5">
                <div
                  v-for="(item,index) in personalInformationData.identity"
                  :key="index"
                  class="main-information-below-div5-li"
                >
                  <label class="main-information-below-div5-li-label1">{{ item.orgName }}</label>
                  <label class="main-information-below-div5-li-label2">身份：{{ item.categoryName }}</label>
                  <label class="main-information-below-div5-li-label3">学工号：{{ item.identity }}</label>
                </div>
              </div>
            </div>
          </el-tab-pane>

          <!--修改密码-->
          <el-tab-pane
            label="修改密码"
            name="changePassword"
          >
            <div class="main-password-up">修改密码</div>
            <div class="main-password-below">

              <my-verification-of-mobile-phone
                ref="phone"
                :is-disable-phone="true"
                :phone-value="phoneValue"
                @returnedValue="returnedValue"
                @getCode="getCode"
              />
              <el-form
                ref="ruleForm"
                style="width: 480px; margin: 0px auto; height: 140px;"
                :model="ruleForm"
              >
                <!--新密码-->
                <el-col
                  :span="24"
                  style="height: 60px;"
                >
                  <el-form-item
                    label="新密码"
                    label-width="120px"
                    prop="newPwd"
                    :rules="[
                      {
                        required: true,
                        message: '请输入内容',
                        trigger: 'change'
                      },
                      {
                        validator: validateNewPwd,
                        trigger: 'blur'
                      }
                    ]"
                  >
                    <el-input
                      v-model="ruleForm.newPwd"
                      type="password"
                      placeholder="请输入新密码"
                      style="width: 240px;"
                      @input="monitor(ruleForm.newPwd)"
                    />
                  </el-form-item>
                </el-col>
                <!--密码强度-->
                <el-col
                  :span="24"
                  style="height: 30px;display: flex;padding-left: 120px"
                >
                  <div class="main-password-below-span2">
                    <div :class="styleSheet1" />
                    <div :class="styleSheet2" />
                    <div :class="styleSheet3" />
                    <div class="main-password-below-span2-styleSheet">{{ styleSheet }}</div>
                  </div>
                </el-col>
                <!--确认新密码-->
                <el-col
                  :span="24"
                  style="height: 60px;"
                >
                  <el-form-item
                    label="确认新密码"
                    label-width="120px"
                    prop="newPwd2"
                    :rules="[
                      {
                        required: true,
                        message: '请输入内容',
                        trigger: 'change'
                      },
                      {
                        validator: validateNewPwd2,
                        trigger: 'blur'
                      }
                    ]"
                  >
                    <el-input
                      v-model="ruleForm.newPwd2"
                      type="password"
                      placeholder="请再输入新密码"
                      style="width: 240px;"
                    />
                  </el-form-item>
                </el-col>

                <el-col
                  :span="24"
                  style="margin: 0px auto; height: 70px; display: flex;"
                >
                  <span
                    style="margin: 0px auto;"
                  >
                    <el-button type="primary" @click="confirm2">确认</el-button>
                  </span>
                </el-col>
              </el-form>
            </div>
          </el-tab-pane>
        </el-tabs>
        <!--修改手机号组件-->
        <modify-the-phone-number
          :dialog-visible="visible"
          @closeModifyThePhone="closeModifyThePhone"
          @confirm="confirm"
        />
      </div>
    </div>

  </div>
</template>

<script>
/*
   * 更换手机弹窗
   */
import ModifyThePhoneNumber from './ModifyThePhoneNumber';

/*
   * 手机验证组件
   */
import MyVerificationOfMobilePhone from '../../../components/myVerificationOfMobilePhone/index';

/*
   *api
   */
import {
  user,
  sendSms,
  checkSms,
  updatePwd
} from '../../../api/index';

/*
   * 工具类
   */
import {
  isSex,
  isCertificateType,
  isNewPwdBlank,
  isNewPwdChinese,
  isNewPwdStrength,
  http, isNewPwdStrengthStronger, isNewPwdStrengthWeak
} from '../../../util/validate';

export default {
  // import引入的组件需要注入到对象中才能使用",
  components: { ModifyThePhoneNumber, MyVerificationOfMobilePhone },
  data() {
    // 这里存放数据",
    return {
      styleSheet: '', // 较弱,一般,较强
      styleSheet1: 'gray', // gray 初色灰色, red1 低红色 red2 中红色, red3 高红色
      styleSheet2: 'gray',
      styleSheet3: 'gray',

      activeName: 'personalInformation',
      visible: false,

      phoneValue: '',
      valid: '',
      phone: '',
      ruleForm: {
        newPwd: '',
        newPwd2: '',
        mobileNumber: '',
        code: '',
        passCode: ''
      },

      /*
        *   ID  userId
        *   姓名  name
        *
        *  头像地址	无
        *  性别	sex 1男2女0待完善
        *  电话	phone
        *  邮箱	email
        *  证件类型	certificateType
			  *    身份证 idcard
			  *    护照 passport
			  *    军官证 militaryId
        *
        *  证件号		certificateNum
        *
        *  身份信息：
        *    标题		orgName
        *    身份		categoryName
        *    学工号		identity
        * */
      personalInformationData: '',
      sex: '',
      email: '待完善',
      certificateType: '',
      imgSrc: require('../../../assets/img/personal/teachers-grey.png'),
      data: {
        'code': 200,
        'data': {
          'orgInstanceIds': [
            '8-f-rZBjlspSQAW47jORtpwwWw'
          ],
          'userClusters': [
            'BVpgmcn7Qd-6j-RXEj5Eqw'
          ],
          'roles': [
            'default'
          ],
          'orgInstanceId': '8-f-rZBjlspSQAW47jORtpwwWw',
          'orgIds': [
            'rZBjlspSQAW47jORtpwwWw'
          ],
          'extWorkNo': 'testId',
          'identity': [
            {
              'categoryId': '8-f',
              'categoryName': '教职工',
              'ext': null,
              'extObj': {
                'extWorkNo': 'testId',
                'isBeingActive': '1',
                'userType': '4'
              },
              'identity': 'testId',
              'orgId': 'rZBjlspSQAW47jORtpwwWw',
              'orgName': '吉首大学',
              'sn': 0
            }
          ],
          'isBeingActive': '1',
          'id': 's7xJyiahyRhukv9s4ft1nJQ',
          'email': '',
          'ext': '',
          'orgInstances': [
            '8-f-rZBjlspSQAW47jORtpwwWw'
          ],
          'userClusterId': 'BVpgmcn7Qd-6j-RXEj5Eqw',
          'sex': '0',
          'userId': 's7xJyiahyRhukv9s4ft1nJQ',
          'realname': '教职工测试',
          'workno': '',
          'categoryIds': [
            '8-f'
          ],
          'phone': '13533323222',
          'name': '教职工测试',
          'userType': '4',
          'certificateNum': 'sfzh',
          'authLevel': '0',
          'account': 'testId',
          'certificateType': 'other'
        },
        'msg': '获取成功'
      }
    };
  },
  // 监听属性 类似于data概念",
  computed: {},
  // 监控data中的数据变化",
  watch: {},
  // 生命周期 - 创建之前",数据模型未加载,方法未加载,html模板未加载
  beforeCreate() {

  },

  // 生命周期 - 创建完成（可以访问当前this实例）",数据模型已加载，方法已加载,html模板已加载,html模板未渲染
  created() {

  },
  // 生命周期 - 挂载之前",html模板未渲染
  beforeMount() {

  },

  // 生命周期 - 挂载完成（可以访问DOM元素）",html模板已渲染
  mounted() {
    this.userPersonalInfo();
  },
  // 生命周期 - 更新之前",数据模型已更新,html模板未更新
  beforeUpdate() {

  },
  // 生命周期 - 更新之后",数据模型已更新,html模板已更新
  updated() {

  },
  // 生命周期 - 销毁之前",
  beforeDestroy() {

  },
  destroyed() {

  },
  // 生命周期 - 销毁完成",
  // 如果页面有keep-alive缓存功能，这个函数会触发",
  activated() {

  },
  // 方法集合",
  methods: {
    // 实时校验密码
    monitor(pwd) {
      this.styleSheet = '';
      this.styleSheet1 = 'gray';
      this.styleSheet2 = 'gray';
      this.styleSheet3 = 'gray';
      if (isNewPwdStrengthStronger(pwd)) {
        this.styleSheet = '较强';
        this.styleSheet1 = 'red1';
        this.styleSheet2 = 'red2';
        this.styleSheet3 = 'red3';
      } else if (isNewPwdStrength(pwd)) {
        this.styleSheet = '一般';
        this.styleSheet1 = 'red1';
        this.styleSheet2 = 'red2';
      } else if (pwd === '') {
        this.styleSheet = '';
        this.styleSheet1 = 'gray';
        this.styleSheet2 = 'gray';
        this.styleSheet3 = 'gray';
      } else {
        this.styleSheet1 = 'red1';
        this.styleSheet = '较弱';
      }
    },
    confirm() {
      this.userPersonalInfo();
    },
    /* 返回上一页*/
    previousPage() {
      history.go(-1);
    },
    /*
      * 下一步校验
      * 校验验证码（找回密码）
      * login/checkSms
      * get
      * code=200通过,返回短信校验成功码，500已失效
      */
    confirm2() {
      let that = this;
      that.$refs.phone.confirm();
      that.$refs['ruleForm'].validate(vd => {
        if (vd && that.valid) {
          if (that.phone !== '') {
            checkSms({
              mobileNumber: that.ruleForm.mobileNumber,
              code: that.ruleForm.code
            }).then(res => {
              if (res.data.code === 200) {
                that.ruleForm.passCode = res.data.data;
                /*
                  * 新密码提交
                  * 带上短信校验成功码，更新密码（找回密码）
                  * /login/updatePwd
                  * get
                  * code=200成功，500非法访问
                  */
                updatePwd({
                  passCode: that.ruleForm.passCode,
                  mobileNumber: that.ruleForm.mobileNumber,
                  newPwd: that.ruleForm.newPwd2
                }).then(function(res2) {
                  if (res2.data.code === 200) {
                    that.$message.success(res2.data.msg);
                    that.activeName = 'personalInformation';
                    that.$refs.ruleForm.resetFields();
                    that.$refs.phone.clearMessage();
                    that.valid = '';
                    that.phone = '';
                    let redirectUrl = encodeURIComponent(http() + '/user/loginOut');
                    window.location.href = 'https://portal.jsu.edu.cn/oauth2/logout?client_id=Bz6n8VwRQRG43tVxrWVxPw&response_type=code' +
                        '&redirect_uri=' + redirectUrl;
                  } else {
                    that.$message.error(res2.data.msg);
                    return false;
                  }
                }).catch(err2 => {
                  if (err2.response) {
                    that.$message.error(err2.response.data.errorDescription);
                    let loginUrl = err2.response.headers.loginurl;
                    if (loginUrl) {
                      let backUrl = window.location.hash;
                      let localBaseIndexOf = loginUrl.indexOf('redirect_uri=');
                      if (localBaseIndexOf != -1) {
                        let localBase = loginUrl.substring(localBaseIndexOf + 13, loginUrl.indexOf('oauth2-login'));
                        backUrl = window.location.href.replace(localBase, '');
                      }
                      window.location.href = loginUrl + '?backUrl=' + encodeURIComponent(backUrl);
                    }
                  }
				          });
              } else {
                that.$message.error(res.data.msg);
                return false;
              }
            }).catch(err => {
              if (err.response) {
                that.$message.error(err.response.data.errorDescription);
                let loginUrl = err.response.headers.loginurl;
                if (loginUrl) {
                  let backUrl = window.location.hash;
                  let localBaseIndexOf = loginUrl.indexOf('redirect_uri=');
                  if (localBaseIndexOf != -1) {
                    let localBase = loginUrl.substring(localBaseIndexOf + 13, loginUrl.indexOf('oauth2-login'));
                    backUrl = window.location.href.replace(localBase, '');
                  }
                  // 跳转到系统登录页面
                  // debugger
                  window.location.href = loginUrl + '?backUrl=' + encodeURIComponent(backUrl);
                }
              }
            });
          } else {
            that.$message.warning('请先获取验证码，并输入正确的验证码！');
            return false;
          }
        } else {
          return false;
        }
      });
    },
    /*
      * 手机校验
      * 获取已绑定手机号的验证码（找回密码）
      * /login/sendSms
      * get
      * code=200发送成功，404此手机号未绑定用户,500返回调用失败
      */
    getCode(phone) {
      let that = this;
      this.phone = phone;
      sendSms({
        mobileNumber: phone
      }).then(res => {
        if (res.data.code === 200) {
          that.$message.success(res.data.msg);
        } else {
          that.$message.error(res.data.msg);
        }
      }).catch(err => {
        if (err.response) {
          that.$message.error(err.response.data.errorDescription);
          let loginUrl = err.response.headers.loginurl;
          if (loginUrl) {
            let backUrl = window.location.hash;
            let localBaseIndexOf = loginUrl.indexOf('redirect_uri=');
            if (localBaseIndexOf != -1) {
              let localBase = loginUrl.substring(localBaseIndexOf + 13, loginUrl.indexOf('oauth2-login'));
              backUrl = window.location.href.replace(localBase, '');
            }
            // 跳转到系统登录页面
            // debugger
            window.location.href = loginUrl + '?backUrl=' + encodeURIComponent(backUrl);
          }
        }
      });
    },
    /*
      * 调用子组件confirm方法
      * 返回：
      * 电话 phone，
      * 短信 message，
      * valid 是否校验成功
      */
    returnedValue(phone, message, valid) {
      this.valid = valid;
      this.ruleForm.mobileNumber = phone;
      this.ruleForm.code = message;
    },
    /* 确认密码*/
    validateNewPwd2(rule, value, callback) {
      if (value) {
        if (this.ruleForm.newPwd) {
          if (value === this.ruleForm.newPwd) {
            callback();
          } else {
            callback(
              new Error('两次密码输入不一致，请重新输入')
            );
            this.$alert('两次密码输入不一致，请重新输入', '警告', {
              confirmButtonText: '确定'
            });
          }
        } else {
          callback(
            new Error('两次密码输入不一致，请重新输入')
          );
          this.$alert('两次密码输入不一致，请重新输入', '警告', {
            confirmButtonText: '确定'
          });
        }
      } else {
        callback();
      }
    },
    /* 校验新密码*/
    validateNewPwd(rule, value, callback) {
      if (value) {
        if (isNewPwdBlank(value)) {
          if (isNewPwdChinese(value)) {
            if (isNewPwdStrength(value)) {
              callback();
            } else {
              callback(
                new Error('不符合密码规则，请确认后输入！')
              );
              this.$alert('不符合密码规则，请确认后输入！密码规则：由字母大小写，数字，特殊字符三种组成的8~16位长度字符', '警告', {
                confirmButtonText: '确定'
              });
            }
          } else {
            callback(
              new Error('格式错误，存在中文')
            );
          }
        } else {
          callback(
            new Error('格式错误，存在空格')
          );
        }
      } else {
        callback();
      }
    },
    /*
      * Id值转义
      * */
    conversion() {
      // 性别
      if (this.personalInformationData.sex) {
        this.sex = isSex(this.personalInformationData.sex);
      }
      // 身份类型
      if (this.personalInformationData.certificateType) {
        this.certificateType = isCertificateType(this.personalInformationData.certificateType);
      }
      // 判断是否为空
      if (this.personalInformationData.email) {
        this.email = this.personalInformationData.email;
      }
      /* this.personalInformationData.identity*/
      let val = '';
      this.personalInformationData.identity.forEach(item => {
        if (item.categoryName === '教职工') {
          val = '教职工';
        }
      });
      if (val !== '教职工') {
        if (this.sex === '女') {
          this.imgSrc = require('../../../assets/img/personal/female-blue.png');
        } else if (this.sex === '男') {
          this.imgSrc = require('../../../assets/img/personal/male-blue.png');
        }
      }
    },
    /*
       * 获取当前登录用户信息
       * /user
       * get
       * code=200查询成功，500获取失败
       */
    userPersonalInfo() {
      /* this.personalInformationData = this.data.data;
        this.phoneValue = this.personalInformationData.phone
        this.conversion();
        console.log(this.personalInformationData,"personalInformationData");*/
      let that = this;
      user().then(res => {
        if (res.data.code === 200) {
          this.personalInformationData = res.data.data;
          this.phoneValue = this.personalInformationData.phone;
          this.conversion();
        } else {
          that.$message.error(res.data.msg);
        }
      }).catch(err => {
        if (err.response) {
          that.$message.error(err.response.data.errorDescription);
          let loginUrl = err.response.headers.loginurl;
          if (loginUrl) {
            let backUrl = window.location.hash;
            let localBaseIndexOf = loginUrl.indexOf('redirect_uri=');
            if (localBaseIndexOf != -1) {
              let localBase = loginUrl.substring(localBaseIndexOf + 13, loginUrl.indexOf('oauth2-login'));
              backUrl = window.location.href.replace(localBase, '');
            }
            // 跳转到系统登录页面
            // debugger
            window.location.href = loginUrl + '?backUrl=' + encodeURIComponent(backUrl);
          }
        }
      });
    },
    modifyThePhone() {
      this.visible = true;
    },
    // 关闭
    closeModifyThePhone(val) {
      this.visible = val;
    }
  }
};
</script>

<style scoped lang="scss">
  .gray {
    width: 60px;
    height: 15px;
    background-color: rgba(	119,136,153,0.2);
    margin-right: 6px;
    margin-top: 1px;
  }
  .red1 {
    width: 60px;
    height: 15px;
    background-color: rgba(245,108,108,0.4);
    margin-right: 6px;
    margin-top: 1px;
  }
  .red2 {
    width: 60px;
    height: 15px;
    //background-color: rgba(255,165,0,0.7);//goldEnrod
    background-color: rgba(245,108,108,0.7);
    margin-right: 6px;
    margin-top: 1px;
  }
  .red3 {
    width: 60px;
    height: 15px;
    //background-color: rgba(103,194,58,0.9);//green
    background-color: rgba(245,108,108,1);
    margin-right: 6px;
    margin-top: 1px;
  }
  /deep/.el-form-item__label {
    font-size: 15px;
  }
  /deep/.el-tabs__item {
    font-size: 18px !important;
    width: 300px;
  }
  /deep/.el-tabs--left .el-tabs__item.is-left {
    text-align: center;
  }
  /deep/.el-tabs__item.is-active {
    background-color: rgba(199,25,33,0.1);
  }
#personalCenter {
  width: 100%;
  height: 1200px;
  overflow: hidden;
  /*height: 1000px;
  display: flex;
  position: relative;
  padding: 20px;*/

  .header {
    height: 150px;
    width: 100%;
    background-image: url("../../../assets/img/login/beijin1.png");
    background-position:center;
    background-size:100% 100%;

  }
  .up {
    width: 100%;
    height: 60px;
    display: flex;
    color: rgba(255,255,255,0.9);
    font-size: 14px;
    background-color: #c71921;
    position: relative;

    &-left {
      position: absolute;
      left: 30px;
      width: 100px;
      height: 60px;
      line-height: 60px;

    }
    &-right {
      position: absolute;
      top: 50%;
      right: 30px;
      width: 70px;
      height: 30px;
      transform: translate(0%, -50%);
      display: flex;
      cursor: pointer;//鼠标变手指
      color: white;

      &-i1 {
        width: 30px;
        height: 30px;
        line-height: 30px;
        text-align: center;
        cursor: pointer;//鼠标变手指
      }
      &-i2 {
        height: 30px;
        line-height: 30px;
        cursor: pointer;//鼠标变手指
      }
    }
  }

  .below {
    border: 1px solid red;
    width: 100%;
    height: 1000px;
    display: flex;
    position: relative;
    background-image: url("../../../assets/img/login/beijin2.jpg");
    background-position:center;
    background-size:100% 100%;

  }

  .main {
    padding: 50px;
    width: 70%;
    height: 750px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    border-radius: 20px;
    background-color: rgba(255,255,255,0.9);
    box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 10px rgba(0, 0, 0, .14);

    &-information {
      display: flex;

      &-up {
        border-bottom: 1px solid rgba(43,42,51,0.5);
        width: 95%;
        height: 50px;
        line-height: 50px;
        font-size: 15px;
        margin: 0px auto;
        color: rgba(43,42,51,0.7);
        font-weight: 600;
      }
      &-below {
        background-color: rgba(255,255,255,1);
        border-radius: 5px;
        width: 95%;
        display: flex;
        flex-wrap: wrap;
        margin: 20px auto;

        &-div1 {
          width: 130px;
          height: 130px;
          margin: 10px auto;
          border-radius: 50%;
          box-shadow: 0 2px 4px rgba(0, 0, 0, .22), 0 0 10px rgba(0, 0, 0, .04);
        }
        &-div2 {
          margin-top: 10px;
          width: 100%;
          height: 30px;
          line-height: 30px;
          text-align: center;
          font-size: 13px;
          color: rgba(43,42,51,0.7);
          display: flex;

          &-label {
            margin: 0px auto;
            height: 100%;
            width: 130px;
            /*border-radius: 3px;
            box-shadow: 0 2px 4px rgba(0, 0, 0, .22), 0 0 10px rgba(0, 0, 0, .04);*/
          }
        }
        &-div3 {
          padding-left: 20px;
          width: 100%;
          height: 30px;
          line-height: 30px;
          font-size: 15px;
          color: rgba(43,42,51,0.7);
          margin-top: 30px;
          font-weight: 600;
        }
        &-div4 {
          padding-left: 25px;
          width: 100%;
          height: 100px;
          padding-top: 10px;
          padding-bottom: 30px;
          display: flex;
          flex-wrap: wrap;
          border-bottom: 1px solid rgba(43,42,51,0.5);
          overflow: auto;

          &-li {
            margin: 0px auto;
            height: 40px;
            width: 240px;
            text-align: left;
            line-height: 40px;
            font-size: 14px;
            color: rgba(43,42,51,0.7);
            cursor: pointer;//鼠标变手指
            /*border-radius: 3px;
            box-shadow: 0 2px 4px rgba(0, 0, 0, .22), 0 0 10px rgba(0, 0, 0, .04);*/

            &-label {
              margin-left: 8px;
              font-size: 20px;
            }
          }
        }

        &-div5 {
          padding-left: 15px;
          width: 100%;
          height: 180px;
          margin: 0px auto;
          display: flex;
          flex-wrap: wrap;
          overflow: auto;
          /*border-bottom: 1px solid rgba(43,42,51,0.5);*/

          &-li {
            width: 200px;
            height: 150px;
            margin: 10px;
            border-radius: 15px;
            display: flex;
            flex-wrap: wrap;
            box-shadow: 0 2px 4px rgba(0, 0, 0, .42), 0 0 10px rgba(0, 0, 0, .04);

            &-label1 {
              height: 50px;
              width: 100%;
              text-align: center;
              line-height: 50px;
              font-size: 14px;
              border-top-left-radius: 15px;
              border-top-right-radius: 15px;
              color: rgba(43,42,51,0.7);
              background-color: rgba(43,42,51,0.1);
            }
            &-label2 {
              height: 30px;
              width: 100%;
              text-align: center;
              line-height: 30px;
              font-size: 14px;
              color: rgba(43,42,51,0.7);
            }
            &-label3 {
              height: 30px;
              width: 100%;
              text-align: center;
              line-height: 30px;
              font-size: 14px;
              color: rgba(43,42,51,0.7);
            }
          }
        }
      }
    }
    &-password {
      display: flex;

      &-up {
        border-bottom: 1px solid rgba(43,42,51,0.5);
        width: 95%;
        height: 50px;
        line-height: 50px;
        font-size: 15px;
        margin: 0px auto;
        color: rgba(43,42,51,0.7);
        font-weight: 600;

      }

      &-below {
        background-color: rgba(255,255,255,1);
        border-radius: 5px;
        margin: 20px auto;
        height: 350px;
        width: 95%;
        padding: 50px;

        &-span2 {
          width: 240px;
          height: 20px;
          display: flex;
          flex-wrap: wrap;

          &-styleSheet {
            width: 40px;
            height: 20px;
            color: #F56C6C;
            text-align: center;
            font-size: 12px;
          }
        }
      }
    }

  }
}
</style>
